<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    /**
     * Size of the spinner in px
     */
    size?: number
    darkMode?: boolean | 'auto'
  }>(),
  {
    size: 20,
    darkMode: 'auto',
  },
)

const spinnerStyles = computed(() => ({
  width: props.size + 'px',
  height: props.size + 'px',
}))
</script>

<script lang="ts">
export default {}
</script>

<template>
  <span
    :class="[
      'block animate-spin rounded-full box-border border-2 border-solid',
      {
        'border-light-text-400 border-t-canary-500': darkMode === true,
        'border-dark-text-400 border-t-grape-500': darkMode === false,
        'border-dark-text-400 dark:border-light-text-400 border-t-grape-500 dark:border-t-canary-500':
          darkMode === 'auto',
      },
    ]"
    :style="spinnerStyles"
  />
</template>
